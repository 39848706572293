<template>
  <div class="menu-wrap">
    <aside class="menu-area">
      <ul class="menu-list">
        <li class="has-icons-left">
          <a href="/">
            <span class="icon-text">
              <span class="icon">
                <i class="fa-solid fa-border-all"></i>
              </span>
              <span>렌탈 주문</span>
            </span>
          </a>
        </li>
        <hr />
        <li class="has-icons-left">
          <a @click="logout">
            <span class="icon-text">
              <span class="icon">
                <i class="fa-solid fa-arrow-right-from-bracket"></i>
              </span>
              <span>로그아웃</span>
            </span>
          </a>
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store.dispatch("user/LOGOUT").then((res) => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style>
.menu-area {
  position: absolute;
  z-index: 1;
  padding: 178px 40px;
}
</style>
