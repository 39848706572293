//렌탈 일 수 계산
export const calcRentalDays = (startDate, endDate) => {
  if (startDate) {
    const oneDay = 1000 * 60 * 60 * 24;
    startDate = startDate.replace("Z", "");
    endDate = endDate.replace("Z", "");

    const diffDays = Math.floor((new Date(endDate) - new Date(startDate)) / oneDay);
    return diffDays + 1;
  }
};

export const calcUnitDays = (startDate, endDate, base) => {
  if (startDate) {
    const oneDay = 1000 * 60 * 60 * 24;
    startDate = startDate.replace("Z", "");
    endDate = endDate.replace("Z", "");

    const diffDays = Math.floor((new Date(endDate) - new Date(startDate)) / oneDay);

    if (diffDays === 0) {
      return 1;
    }

    return Math.ceil((diffDays + 1) / base);
  }
};

//옵션 가격 합 계산
export const calOptionPrice = (item) => {
  const optionPrices = item.option.map((option) => option.price);
  const totalPrice = optionPrices.reduce((total, price) => total + price, 0);
  return totalPrice;
};

//23년 9월 1일 이전 생성된 주문 배송비 계산(추후 삭제?)
export const calcOldDeliveryFee = (total, sido) => {
  const capital_region = ["서울", "경기", "인천"];
  const shipping_fee = total > 1500000 ? 0 : capital_region.includes(sido) ? 200000 : 400000;
  return shipping_fee;
};

//배송비 계산
export const calcDeliveryFee = (total, sido) => {
  const sub_sido = sido.substr(0, 2);

  const regions = {
    서울: 0,
    경기: 0,
    인천: 0,
    강원: 1,
    충북: 1,
    충남: 1,
    세종: 1,
    대전: 1,
    전북: 2,
    전남: 2,
    광주: 2,
    경북: 2,
    경남: 2,
    대구: 2,
    부산: 2,
    울산: 2,
    제주: 2,
  };

  const deliveryFees = {
    0: { minTotal: 2000000, fee: 220000 },
    1: { minTotal: 3000000, fee: 440000 },
    2: { minTotal: 3000000, fee: 550000 },
  };

  const groupNumber = regions[sub_sido];
  if (groupNumber === undefined) {
    throw new Error(`Invalid location: ${sido}`);
  }

  const feeInfo = deliveryFees[groupNumber];
  return total >= feeInfo.minTotal ? 0 : feeInfo.fee;
};

//할인 금액 계산
export const calcDiscount = (selected_coupon, total) => {
  if (!selected_coupon) return 0;

  if (selected_coupon.type === 0) {
    const discount = total * (selected_coupon.price_terms / 100);
    return Math.min(discount, selected_coupon.max_price);
  }

  return selected_coupon.amount;
};

//제품 총액 계산
export const calcProductTotal = (cartlist, startDate, endDate) => {
  return cartlist.reduce((acc, item) => {
    const optionPrice = calOptionPrice(item);
    const unit_days = calcUnitDays(startDate, endDate, item.product.base_rental_days);
    const itemTotal = (optionPrice + item.product.price) * item.quantity * unit_days;
    return acc + itemTotal;
  }, 0);
};

//선입금 반환
export const returnDeposit = (down_payment, calculated_deposit) => {
  if (down_payment === null) {
    return calculated_deposit;
  } else if (down_payment === 1) {
    return 0;
  } else {
    return down_payment;
  }
};
