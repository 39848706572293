import { createWebHistory, createRouter, onBeforeRouteLeave } from "vue-router";
import { store } from "./store";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/",
    name: "rentalList",
    component: () => import("@/views/RentalList"),
    props: true,
  },
  {
    path: "/rental/:id(\\d+)",
    name: "rentalDetail",
    component: () => import("@/views/Rental"),
    props: true,
  },
  {
    path: "/rental/edit/:order_num/:stage",
    name: "rentalEdit",
    component: () => import("@/views/RentalEdit"),
    props: true,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

async function isAuthenticated() {
  try {
    const res = await store.dispatch("user/GET_USERINFO");
    if (res.status == 200 || res.status == 201) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  if (to.name !== "login" && !(await isAuthenticated())) {
    next({ name: "login" });
  } else {
    if (to.name !== "rentalEdit") {
      store.commit("order/RESET_ORDER");
    }
    next();
  }
});
