<template>
  <div class="header-wrap">
    <div class="header-area">
      <div class="welcome-msg">
        <div class="title is-5">
          {{ `Hello ${user_name}!` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./Menu.vue";
export default {
  components: {
    Menu,
  },
  mounted() {},
  data() {
    return {
      toggleMenu: false,
      user_name: this.$store.state.user.user_name,
    };
  },
  methods: {},
};
</script>

<style>
.header-wrap{
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  align-items: center;
}
.header-area {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
</style>
