export const formatDateTime = (dateStr) => {
  const inputDate = new Date(dateStr);
  const year = inputDate.getFullYear();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const hours = inputDate.getHours().toString().padStart(2, "0");
  const minutes = inputDate.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const formatDateRange = (date) => {
  if (!Array.isArray(date) || date.length !== 2) {
    throw new Error(
      "Invalid date format. Expecting an array of [start_date, end_date]."
    );
  }

  const formatted_dates = date.map((d, index) => {
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");

    return index === 0
      ? `${year}-${month}-${day}T00:00:00`
      : `${year}-${month}-${day}T23:59:59`;
  });

  return formatted_dates;
};

export const formatDatetimeRange = (date) => {
  if (!Array.isArray(date) || date.length !== 2) {
    throw new Error(
      "Invalid date format. Expecting an array of [start_date, end_date]."
    );
  }

  const formatted_dates = date.map((d) => {
    const utcDate = new Date(d.toISOString());
    const kstOffset = 9 * 60;
    const kstDate = new Date(utcDate.getTime() + kstOffset * 60 * 1000);

    return kstDate.toISOString();
  });

  return formatted_dates;
};
